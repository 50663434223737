<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center py-3 logo-container w-full">
      <img
        :src="require('../../../../assets/images/fcLOgo.svg')"
        alt="Fresh Clinics"
        width="251"
        height="56"
        @click="gotoFcSite()"
      />
    </div>
    <div class="w-11/12 mx-auto my-8 px-8 pt-8 pb-16 rounded-lg bg-login login-tabs-container">
      <div>
        <div class="vx-card__title mb-6">
          <h4 class="form-heading text-base">Reset your password ✉️</h4>
        </div>
        <div>
          <vs-alert
            id="alert"
            v-if="this.emailError === false"
            :active.sync="showDismissibleAlert"
            closable
            icon-pack="feather"
            close-icon="icon-x"
            color="success"
            >{{ message }}</vs-alert
          >
          <vs-alert
            v-else
            :active.sync="showDismissibleAlert"
            closable
            icon-pack="feather"
            close-icon="icon-x"
            color="danger"
            >{{ message }}</vs-alert
          >
          <form v-on:submit.prevent="recoverPassword">
            <span class="form-label"
              >We will send you a reset password email</span
            >
            <vs-input
              :danger="errors.first('email')"
              :success="!errors.first('email') && user.email != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required|email|min:3'"
              data-vv-validate-on="blur"
              name="email"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              label-placeholder=""
              v-model="user.email"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first("email") }}</span>
          </form>
          <vs-button
            :disabled="!validateForm"
            @click="recoverPassword"
            class="w-full my-6"
            >Reset Password</vs-button
          >

          <div
            @click="login()"
            class="flex items-center justify-center cursor-pointer w-full"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0833 14.4167C13.4167 14.75 13.4167 15.25 13.0833 15.5833C12.9167 15.75 12.75 15.8333 12.5 15.8333C12.25 15.8333 12.0833 15.75 11.9167 15.5833L6.91667 10.5833C6.58334 10.25 6.58334 9.75 6.91667 9.41667L11.9167 4.41666C12.25 4.08333 12.75 4.08333 13.0833 4.41666C13.4167 4.75 13.4167 5.25 13.0833 5.58333L8.66667 10L13.0833 14.4167Z"
                fill="black"
              />
              <mask
                id="mask0_1025_108583"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="6"
                y="4"
                width="8"
                height="12"
              >
                <path
                  d="M13.0833 14.4167C13.4167 14.75 13.4167 15.25 13.0833 15.5833C12.9167 15.75 12.75 15.8333 12.5 15.8333C12.25 15.8333 12.0833 15.75 11.9167 15.5833L6.91667 10.5833C6.58334 10.25 6.58334 9.75 6.91667 9.41667L11.9167 4.41666C12.25 4.08333 12.75 4.08333 13.0833 4.41666C13.4167 4.75 13.4167 5.25 13.0833 5.58333L8.66667 10L13.0833 14.4167Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_1025_108583)">
                <rect width="20" height="20" fill="#47E7B8" />
              </g>
            </svg>
            <a class="px-2">Back To Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validate } from "uuid";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user: {
        email: "",
      },
      showDismissibleAlert: false,
      message: "",
      emailError: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "";
    },
  },
  methods: {
    ...mapActions("users", ["forgotPassword"]),
    recoverPassword() {
    if(!this.validateForm)return
      const payload = {
        userDetail: this.user,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$store
            .dispatch("auth/forgotPwd", payload)
            .then((response) => {
              this.emailError = false;
              this.showDismissibleAlert = true;
              this.message = response.data.message;
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.emailError = true;
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
            });
        }
      });
    },
    gotoFcSite(){
      window.open(process.env.VUE_APP_FRESHCLINICS_V2_SITE)
    },
    login() {
      this.displayLoader();
      this.$router.push("/login");
    },
    displayLoader() {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  max-width: 26rem;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.form-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0e4333;
}

.logo-container {
  border-bottom: 2px solid #ebe9f1;
}
.vs-input--input,
.vs-input--input.normal {
  font-size: 16px !important;
}
</style>
